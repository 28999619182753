<template>
  <main class="home">
    <hero-carousel :slides="hero"/>

    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section">
          <b-col md="5">
            <h1 class="mb-md-0">Improving clinical management and outcomes for people with Parkinson’s disease.</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5 mb-5">Kinetikos Health assists healthcare providers and patients in the management of Parkinson’s disease, through an evidence-based clinical platform and smartphone app that continuously collect and process objective movement data, symptomatology and medication adherence. This helps clinicians to better manage their patients, individuals their condition and institutions their resources, leading to increased healthcare efficiency and patient’s quality of life.</p>

            <b-link class="custom-link light mr-5" :to="{name: 'mkinetikos'}">FOR INDIVIDUALS</b-link>
            <b-link class="custom-link light mr-5" :to="{name: 'kinetikos'}">FOR HCP</b-link>
            <b-link class="custom-link light" :to="{name: 'research'}">FOR CLINICAL TRIALS</b-link>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary-half d-none d-md-flex">
      <b-container>
        <b-row class="sub-section">
          <b-col>
            <b-embed type="video" aspect="16by9" controls>
              <source src="@/assets/mk-video.mp4" type="video/mp4"/>
            </b-embed>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <b-row class="sub-section">
          <b-col md="5">
            <h1>Why you should monitor Parkinson’s disease in continuum</h1>
          </b-col>
          <b-col offset-md="1">
            <ul>
              <li class="h5 mb-5">Long-term condition with no cure and progression rate is variable</li>
              <li class="h5 mb-5">Yearly, 2 in 5 people with PD are admitted as an emergency in hospital facilities</li>
              <li class="h5 mb-5">Many admissions such as falls and infections can be prevented and earlier problems predicted through remote monitoring</li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6">
            <b-img src="@/assets/img/home-mkinetikos.png" alt="mKinetikos app for Parkinsons" fluid/>
          </b-col>
          <b-col md="5" offset-md="1">
            <h4 class="mb-5 mute">mKinetikos App — Patients / Caregivers</h4>
            <p class="h5 mb-5">mKinetikos is a safe and clinically-validated app designed to continuously monitor movement and track your medication intake, activities and symptoms, so that you’re able to self-monitor, self-report issues and receive adequate treatment.</p>
            <b-button class="mt-5" variant="primary" :to="{name: 'mkinetikos'}">LEARN MORE</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6" offset-md="1" order-md="2">
            <b-img src="@/assets/img/home-kinetikos.png" alt="kinetikos platform" fluid/>
          </b-col>
          <b-col md="5">
            <h4 class="mb-5 mute">Kinetikos Platform – HCP</h4>
            <p class="h5 mb-5">The Kinetikos monitoring platform offers evidence-based features that are unique and useful to clinicians. From medication adherence to patient risk stratification, we support clinical decisions and reduce the workload on healthcare providers.</p>
            <b-button class="mt-5" variant="primary" :to="{name: 'kinetikos'}">LEARN MORE</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <b-row class="sub-section" md="2" align-h="between">
          <b-col md="5">
            <h1>Kinetikos Pro - RESEARCH</h1>
          </b-col>
          <b-col md="5">
            <p class="mb-5">Kinetikos provides researchers and clinical trials with a fast and easy-to-use interface for human movement reconstruction, allowing search for relevant patterns which complement investigation.</p>
            <b-button variant="primary" v-b-modal.book-demo>Order a demo</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section" id="how-it-works">
      <b-container>
        <h1>How it works</h1>
        <b-row class="sub-section" md="2" align-h="between">
          <b-col md="5" class="mb-4">
            <b-img class="img-drop-shadow mb-3" src="@/assets/img/home-works-1.png" alt="patient checking mKinetikos mobile app" fluid/>
            <h2 class="mute mt-4">01.</h2>
            <h3>Monitor</h3>
            <p>Patient downloads mKinetikos app and continues daily living activities. We use smartphone sensors to collect movement data. Invite your clinician to connect.</p>
          </b-col>
          <b-col sm="12" md="5" class="mb-4">
            <b-img class="img-drop-shadow mb-3" src="@/assets/img/home-works-2.png" alt="telehealth tool for clinical care and clinical trials in movement disorders" fluid/>
            <h2 class="mute mt-4">02.</h2>
            <h3>Analyse</h3>
            <p>Data collected is automatically pushed and processed into a secure cloud-based platform, providing real-time objective insights that guide clinicians on the most appropriate treatments.</p>
          </b-col>
          <b-col sm md="5" class="mb-4">
            <b-img class="img-drop-shadow mb-3" src="@/assets/img/home-works-3.png" alt="clinicians communicating with patients" fluid/>
            <h2 class="mute mt-4">03.</h2>
            <h3>Intervene</h3>
            <p>Clinicians can quickly intervene by sending messages, medication and treatment updates or prescribing ad hoc movement assessments. Our e-chat feature also allows for in-person conversations.</p>
          </b-col>
          <b-col sm md="5" class="mb-4">
            <b-img class="img-drop-shadow mb-3" src="@/assets/img/home-works-4.png" alt="continuum healthcare in Parkinson's disease" fluid/>
            <h2 class="mute mt-4">04.</h2>
            <h3>Continuum Care</h3>
            <p>We enable data-driven clinical decisions and improve quality of life through the continuum of care. Our solution also enables a multidisciplinary approach by connecting you with different key providers.</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <!-- REVIEW: we where instructed to hide this for now, the copy needs some reviewing before we carry on. -->
    <!-- <b-row class="main-section kh-bg-secondary">
       TODO: Gonna need to find a third party library for graphs to be able to do the thing with the circles here.
      <b-container>
        <h1>Best-in class results</h1>
        <b-row class="sub-section">
          <b-col>
            <p>Over 90% of patients has have their treatment changed</p>
          </b-col>
          <b-col>
            <p>3% of error when compared with optoelectronic gold standard.</p>
          </b-col>
          <b-col>
            <p>82% Mark ORCHA approved</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row> -->

    <!-- <custom-carousel class="main-section kh-bg-dark text-light" id="our-partners" :slides="partners" :size="4"/> -->

    <b-row class="main-section kh-bg-dark text-light" id="our-partners">
      <b-container>
        <item-carousel :slides="partners" :size="4"/>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row class="badges">
          <b-col class="badge-group" md="4">
            <p class="group-title">Accreditations</p>
            <h5>CE Mark Medical Device Class IIa<br>HIPPA &amp; GDPR Compliant</h5>
          </b-col>
          <b-col class="badge-group align-to-grid" md="4">
            <p class="group-title">Certified by:</p>
            <b-img class="badge-img lg" src="@/assets/img/orcha-dev-badge.png"/>
          </b-col>
          <b-col class="badge-group" md="4">
            <p class="group-title">Co-financed by:</p>
            <b-link :to="{name: 'project-sheet-inter'}">
              <b-img class="badge-img" src="@/assets/img/pt2020-01.svg"/>
            </b-link>
            <b-link :to="{name: 'project-sheet-inter'}">
              <b-img class="badge-img" src="@/assets/img/pt2020-02.svg"/>
            </b-link>
            <b-link :to="{name: 'project-sheet-inter'}">
              <b-img class="badge-img" src="@/assets/img/pt2020-03.svg"/>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <promo-video/>
  </main>
</template>

<script>
import PromoVideo from '@/components/PromoVideo'
import ItemCarousel from '@/components/ItemCarousel'
import HeroCarousel from '@/components/HeroCarousel'

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'Home',
  components: {
    PromoVideo,
    ItemCarousel,
    HeroCarousel
  },
  mounted () {
    this.getHeroSlides()
    this.getPartnerSlides()
  },
  data: () => ({
    hero: [],
    partners: []
  }),
  methods: {
    async getHeroSlides () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/home/carousel/landing/`)
        if (response.ok) {
          const text = await response.json()
          this.hero = text.data.map(h => ({
            title: h.title,
            subtitle: h.subtitle,
            align: h.align,
            img: {
              src_sm: `${apiBaseUrl}/media/${h.img_mobile}`,
              src_md: `${apiBaseUrl}/media/${h.img_web}`,
              alt: h.img_alt
            },
            style: {
              shadow: h.style_shadow,
              light: h.style_light
            },
            url: h.url
          }))
        } else {
          console.error(response.json())
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getPartnerSlides () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/home/carousel/partners/`)
        if (response.ok) {
          const text = await response.json()
          this.partners = text.data.map(p => ({
            src: `${apiBaseUrl}/media/${p.logo}`,
            alt: p.logo_alt
          }))
        } else {
          console.error(response.json())
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
.badges {
  > .badge-group:not(:last-child) {
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  > .badge-group.align-to-grid {
    @media (min-width: 768px) {
      padding-left: 5.3%;
    }
  }
}

.badge-group {
  > .group-title {
    margin-bottom: 1.5rem;
  }
  .badge-img {
    height: 3rem;
    margin-right: 1rem;
  }
  .badge-img.lg {
    height: 3.4rem;
  }
}
</style>
