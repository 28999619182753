<template>
  <b-modal id="book-demo" title="Book a Demo" hide-footer centered>
    <b-form ref="demo" @submit.stop.prevent="onSubmit" novalidate>
      <b-alert variant="success" :show="timeout" @dismiss-count-down="countdown" dismissible>Your request for contact has been submitted.</b-alert>

      <b-form-group id="topic-group">
        <b-select id="topic" v-model="form.topic" required>
          <template #first>
            <b-form-select-option :value="'Request a demo'" disabled>Request a demo</b-form-select-option>
          </template>
        </b-select>
      </b-form-group>
      <b-form-group id="name-group" :invalid-feedback="nameValid.feedback">
        <b-form-input id="name" name="name" v-model="form.name" placeholder="Name" :state="nameValid.state" required/>
      </b-form-group>
      <b-form-group id="email-group" :invalid-feedback="emailValid.feedback">
        <b-form-input id="email" name="email" type="email" v-model="form.email" placeholder="Email" :state="emailValid.state" required/>
      </b-form-group>
      <b-button class="mt-4" type="submit" variant="primary">Submit</b-button>
      <p class="mt-5 mb-4 small">
        <span class="text-mute">We won’t send you any emails other than the selected topic, unless you later sign up for more. For further details, review our </span>
        <b-link class="custom-link" :to="{name: 'privacy'}">Privacy Policy.</b-link>
      </p>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: 'book-demo-modal',
  data: () => ({
    validate: false,
    timeout: 0,
    form: {
      topic: 'Request a demo',
      name: '',
      email: ''
    }
  }),
  computed: {
    nameValid () {
      if (this.validate && !this.form.name.length) {
        return { state: false, feedback: this.$refs.demo.name.validationMessage }
      }
      return { state: null }
    },
    emailValid () {
      if (this.validate && (!this.form.email.length || !this.$refs.demo.email.validity.valid)) {
        return { state: false, feedback: this.$refs.demo.email.validationMessage }
      }
      return { state: null }
    }
  },
  methods: {
    countdown (counter) {
      this.timeout = counter
    },
    async onSubmit () {
      if (!this.$refs.demo.checkValidity()) {
        this.validate = true
        return
      }
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/contact_us/submit/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            contact_type: this.form.topic,
            name: this.form.name,
            email: this.form.email
          })
        })
        if (response.ok) {
          this.$refs.demo.reset()
          this.timeout += 7
        } else {
          const message = await response.json()
          const error = new Error(message.error)
          error.status = response.status
          error.response = response
          throw error
        }
      } catch (e) {
        const error = new Error(e.message)
        error.status = 500
        throw error
      }
    }
  }
}
</script>

<style lang="scss">
  #book-demo {
    .modal-content {
      border-radius: 0;
      background-color: #e7e9ee;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      line-height: 1.6;
      color: #333;
    }
  }
</style>
