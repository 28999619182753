<template>
  <main class="book_a_demo">
    <b-row class="main-section" id="bookademo">
      <b-container>
        <b-row class="sub-section">
          <!-- Google Calendar Appointment Scheduling begin -->
          <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2BzeTL6a8e8_HLg_lFs-OtHER4rnieV3uUWK80TLF67vCFWsPfPyOv_0OAiGrR6UfN5JuUQ1F1?gv=true" style="border: 0" width="100%" height="800" frameborder="0"></iframe>
          <!-- end Google Calendar Appointment Scheduling -->
        </b-row>
      </b-container>
    </b-row>
  </main>
</template>

<script>
export default {
  name: 'BookADemoForm'
}
</script>
