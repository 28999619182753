import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import Kinetikos from '@/views/Kinetikos'
import Research from '@/views/Research'
import MKinetikos from '@/views/MKinetikos'
import About from '@/views/About'
import Blog from '@/views/Blog'
import ContactUsForm from '@/views/ContactUsForm'
import BookADemoForm from '@/views/BookADemoForm'

import Careers from '@/views/Careers'
import BlogPost from '@/views/BlogPost'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Kinetikos Health',
      description: 'Digital health remote monitoring solution for parkinson’s disease that improves quality of life through continuum healthcare.'
    }
  },
  {
    path: '/mkinetikos',
    name: 'mkinetikos',
    component: MKinetikos,
    meta: {
      title: 'mKinetikos - Kinetikos Health',
      description: 'mKinetikos a mobile app that continuously monitors daily living activities and connects you with your healthcare team for person-centered care in Parkinson’s disease.'
    }
  },
  {
    path: '/kinetikos',
    name: 'kinetikos',
    component: Kinetikos,
    meta: {
      title: 'Kinetikos - Kinetikos Health',
      description: 'Discover Kinetikos, movement analysis platform for clinical care, clinical trials and research.'
    }
  },
  {
    path: '/research',
    name: 'research',
    component: Research,
    meta: {
      title: 'Research - Kinetikos Health',
      description: 'Discover Kinetikos Pro, movement analysis and motion platform for clinical trials and research.'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'About - Kinetikos Health',
      description: 'Kinetikos is a digital health company revolutionising the standard of care in movement disorders.'
    }
  },
  {
    path: '/form/contact_us',
    name: 'form_contact_us',
    component: ContactUsForm,
    meta: {
      title: 'Contact Us - Kinetikos Health'
    }
  },
  {
    path: '/form/book_a_demo',
    name: 'form_book_a_demo',
    component: BookADemoForm,
    meta: {
      title: 'Book A Demo - Kinetikos Health'
    }
  },
  {
    path: '/blog',
    name: 'blog',
    // beforeEnter () {
    //   const blog = window.open('https://medium.com/kinetikos-blog', '_blank')
    //   blog.focus()
    // },
    component: Blog,
    meta: {
      title: 'Blog - Kinetikos Health'
    }
  },
  {
    path: '/blog/:slug',
    name: 'blog-post',
    component: BlogPost,
    meta: {
      title: 'Blog - Kinetikos Health'
    }
  },
  {
    path: '/careers/:job',
    name: 'careers',
    component: Careers,
    meta: {
      title: 'Careers - Kinetikos Health'
    }
  },
  {
    path: '/legal/disclaimer',
    name: 'disclaimer',
    component: () => import('@/views/legal/Disclaimer.vue'),
    meta: {
      title: 'Disclaimer - Kinetikos Health'
    }
  },
  {
    path: '/legal/privacy',
    name: 'privacy',
    component: () => import('@/views/legal/Privacy.vue'),
    meta: {
      title: 'Privacy - Kinetikos Health'
    }
  },
  {
    path: '/legal/cookies',
    name: 'cookies',
    component: () => import('@/views/legal/Cookies.vue'),
    meta: {
      title: 'Cookies - Kinetikos Health'
    }
  },
  {
    path: '/pt2020/project-idt',
    name: 'project-sheet-idt',
    component: () => import('@/views/pt2020/ProjectSheetIDT.vue'),
    meta: {
      title: 'Project Sheet IDT - Kinetikos Health'
    }
  },
  {
    path: '/pt2020/project-inter',
    name: 'project-sheet-inter',
    component: () => import('@/views/pt2020/ProjectSheetInter.vue'),
    meta: {
      title: 'Project Sheet IDT - Kinetikos Health'
    }
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('@/views/Resources.vue'),
    meta: {
      title: 'Resources - Kinetikos Health'
    }
  },
  {
    path: '**',
    name: '404',
    component: () => import('@/views/error/PageNotFound.vue'),
    meta: {
      title: '404 - Kinetikos Health'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 48, y: 48 },
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  }
})

const defaultDescription = 'Kinetikos is a smart cloud-based and mobile service designed to capture patient-reported outcomes in Movement Disorders.'

const updateMetaTags = (meta) => {
  document.title = meta.title

  const tagDefs = [
    { name: 'description', content: meta.description || defaultDescription },
    { name: 'twitter:title', content: meta.title },
    { name: 'twitter:description', content: meta.description || defaultDescription },
    { name: 'og:title', content: meta.title },
    { name: 'og:description', content: meta.description || defaultDescription }
  ]

  tagDefs.forEach(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })
    tag.setAttribute('data-vue-router-controlled', '')

    document.head.appendChild(tag)
  })
}

router.beforeEach((to, from, next) => {
  const nearestRouteTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  if (nearestRouteTitle) {
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))
    updateMetaTags(nearestRouteTitle.meta)
  }
  next()
})

export default router
