<template>
  <main class="kinetikos">
    <b-row class="main-section kh-bg-dark text-light" align-v="center">
      <b-col class="mb-5 mb-md-0 text-center" md="6">
        <b-img src="@/assets/img/kinetikos-hero-dashboard.png" alt="kinetikos platform" fluid/>
      </b-col>
      <b-col md="6" lg="5" xl="4">
        <h4 class="mb-5 mute">Discover Kinetikos Platform - Dashboard Version</h4>
        <h1>Patient management platform</h1>
        <p class="h5 mb-5">Monitor and manage the Parkinson’s disease condition of your patients over time. Receive risk alerts and notifications to prevent deterioration, prioritize patients and reduce hospitalisations.</p>
        <b-button class="mb-5" variant="primary" href="https://platform.kinetikos.io/login/" target="_blank">Create an Account</b-button>
        <p class="h5 mb-5">Prescribe the app to your patient. For any helpful resources and tips <b-link class="custom-link" target="_blank" href="https://kinetikoshealth.com/form/contact_us">contact our support</b-link></p>
      </b-col>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row class="sub-section">
          <b-col md="5">
            <h1>Your gateway to over time and objective patient data</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5">Relying on patient's subjective diaries and lack of objective instruments outside clinical visits? The Kinetikos platform collects and processes PwP movement data and disease-relevant assessments via mKinetikos, a smartphone app installed on the patient’s phone, offering clinicians a comprehensive view of patients’ status to enable optimal medication usage, risk monitoring and remote care.</p>
            <b-row class="mt-5">
              <b-col>
                <h2 class="mute">01.</h2>
                <p>Get actionable insights</p>
              </b-col>
              <b-col>
                <h2 class="mute">02.</h2>
                <p>Reduce unnecessary outpatient visits</p>
              </b-col>
              <b-col>
                <h2 class="mute">03.</h2>
                <p>Reduce avoidable hospitalisations</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

     <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <b-row class="sub-section" align-h="between">
          <b-col md="5">
            <h1>Start your Kinetikos journey</h1>
          </b-col>
          <b-col md="6">
            <p>By using our web-based dashboard, you can get continuous and objective real-life measures of a patient’s health and functional mobility. The following appointments will provide you and your patient the opportunity to review the data together. Simply ask your patients to connect and see for yourself.</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6">
            <b-img src="@/assets/img/kinetikos-1.png" alt="kinetikos analytics for healthcare providers" fluid />
          </b-col>
          <b-col md="4" offset-md="2">
            <h2>Self-Management & Tracking</h2>
            <p class="mb-5">As patients track their symptoms and movement is collected in an unsupervised way through the app, clinicians gain a longitudinal perspective on objective data collected between appointments. The patient can also self-report issues such as falls and initiate follow up (PIFU).</p>
          </b-col>
        </b-row>

        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6" offset-md="2" order-md="2">
            <b-img src="@/assets/img/home-kinetikos.png" alt="kinetikos analytics for research" fluid />
          </b-col>
          <b-col md="4">
            <h2>Medication Optimisation</h2>
            <p class="mb-5">As patients record their medication intake and personal medicines, clinicians can monitor medication adherence to ensure optimal usage and reduce problems with gait, balance and potential hospitalisations. Clinicians can also prescribe medication through the app.</p>
          </b-col>
        </b-row>

        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6">
            <b-img src="@/assets/img/kinetikos-1.png" alt="kinetikos analytics for healthcare providers" fluid />
          </b-col>
          <b-col md="4" offset-md="2">
            <h2>Risk Assessment</h2>
            <p class="mb-5">Our risk assessment feature enables clinicians to receive alerts when a patient moves out of set parameters they want to control (scores of bradykinesia, risk of falls, Time Up and Go, Finger Tapping…) and then contact the patient and request more info. An early warning system and advanced analytics provide additional workforce capacity to monitor PwP.</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-tabs nav-wrapper-class="col-12 col-md-4 mb-4" nav-class="custom-tabs benefits" vertical>
          <b-tab title="HCP" active>
            <h2>Resources for healthcare providers</h2>
            <p>Kinetikos platform is designed to support the everyday work of healthcare providers in movement disorders care (neurologists, PD nurses, physiotherapists, GP).</p>
            <b-link class="custom-link light mr-5">(Available Soon) Step-by-step guide for getting started with Kinetikos.</b-link>
          </b-tab>

          <b-tab title="FAQ’s">
            <h2>FAQ’s - Frequently Asked Questions</h2>
            <p>Below, you can find answers to a variety of commonly asked questions related to Kinetikos platform and the mKinetikos app.</p>
            <b-row class="mt-5" cols="1" cols-md="2">
              <b-col class="mb-4">
                <h2 class="mute">01. What does the mKinetikos app measure?</h2>
                <p>The activities in the mKinetikos app provide a healthcare provider real-life measures of a patient’s health and functional mobility, through active disease-relevant tests, patient-rated outcomes and unsupervised passive movement data collection. </p>
              </b-col>
              <b-col class="mb-4">
                <h2 class="mute">02. Is the mKinetikos a symptom tracker?</h2>
                <p>One of the components of the app is symptom tracking, but the app does more than that. The app remotely keeps track of data of a person living with PD through the completion of movement-related assessments and online questionnaires and automatically shares the results with the HCP. In addition, the app also includes a digital journal on physical and mental wellness which empower patients to self-manage their condition. </p>
              </b-col>
              <b-col class="mb-4">
                <h2 class="mute">03. How does Kinetikos handle data?</h2>
                <p>Data is collected and protected according to GDPR compliance.</p>
              </b-col>
              <b-col class="mb-4">
                <h2 class="mute">04. How do I invite my healthcare provider to connect?</h2>
                <p>There is a code available on your profile in the app, which you can share with your HCP through email or in person. Once you’re connected with an Institution, this will show on active connections</p>
              </b-col>
              <b-col class="mb-4">
                <h2 class="mute">05. How do healthcare providers connect with patients?</h2>
                <p>The patient will give you a key code obtained on the mKinetikos app, either by sending you an email or in person, so you can stay connected.</p>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <b-tabs nav-wrapper-class="col-12 col-md-4 mb-4" nav-class="custom-tabs benefits" vertical>
          <b-tab title="Parkinson's Disease" active>
            <h2>Designed for PD</h2>
            <p>Kinetikos provides a continuum and person-centered integrated solution, which combines a secure cloud-based platform and a smartphone app.</p>
            <p class="mb-5">Designed to address the unique challenges posed by Parkinson’s Disease therapy and care. Our proprietary machine learning algorithms are used to fully characterise patient’s movement, filling the gap between infrequent consultations that do not capture fluctuating and rare events of PD.</p>
          </b-tab>

          <b-tab title="Musculoskeletal Disorders">
            <h2>Designed for MSK</h2>
            <p>Kinetikos solution increases clinical assessment, via an intuitive cloud-based platform, by providing actionable insights based on validated musculoskeletal models that accurately reconstruct patient movement.</p>
            <p class="mb-5">Being a proven vehicle for clinical effectiveness, approximately 90% of patients have their treatment plan changed when clinicians have access to a Kinetikos report. Besides improving patients’ quality of life, it’s business improving.</p>
          </b-tab>
        </b-tabs>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row align-v="center">
          <b-col class="mb-4 mb-md-0" md="6">
            <h4 class="mb-0">See how our remote monitoring program works.</h4>
          </b-col>
          <b-col class="text-md-right">
            <b-button variant="primary" :to="{path: '/#how-it-works'}">How it Works</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <item-carousel variant="people" :slides="testimonials" :size="2"/>
      </b-container>
    </b-row>

    <medical-device product="Kinetikos"/>
  </main>
</template>

<script>
import ItemCarousel from '@/components/ItemCarousel'
import MedicalDevice from '@/components/MedicalDevice'

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'Kinetikos',
  components: {
    ItemCarousel,
    MedicalDevice
  },
  mounted () {
    this.getTestimonialSlides()
  },
  data: () => ({
    testimonials: []
  }),
  methods: {
    async getTestimonialSlides () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/about_us/carousel/testimonials/`)
        if (response.ok) {
          const text = await response.json()
          // this.testimonials = text.data
          this.testimonials = text.data.map(t => ({
            name: t.name,
            title: t.title,
            statement: t.statement,
            img: {
              src: `${apiBaseUrl}/media/${t.img}`,
              alt: t.img_alt
            }
          }))
        } else {
          const message = await response.json()
          console.error(message.error)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss">
  .benefits {
    @media (min-width: 768px) {
      margin-top: calc(2em * 1.5 + 1.5rem);
    }
  }
</style>
