<template>
  <b-row class="main-section">
    <b-container>
      <b-row align-v="center">
        <b-col class="mb-5 mb-md-0" md="5">
          <span>{{ product }} is a class IIa medical device according to the Medical Devices Directive (MDR) within the European Union.</span>
        </b-col>
        <b-col md="4" offset-md="3">
        </b-col>
      </b-row>
    </b-container>
  </b-row>
</template>

<script>
export default {
  name: 'medical-device',
  props: {
    product: String
  }
}
</script>
