<template>
  <b-form ref="form" @submit.stop.prevent="onSubmit" novalidate>
    <b-alert variant="success" :show="timeout" @dismiss-count-down="countdown" dismissible>Your request for contact has been submitted.</b-alert>

    <b-alert variant="danger" :show="errorTimeout" @dismiss-count-down="errorCountdown" dismissible>We seem to be having some issues. Please try again in a few moments.</b-alert>

    <b-form-group id="topic-group" :invalid-feedback="topicValid.feedback">
      <b-select id="topic" name="topic" v-model="form.topic" :options="topics" :state="topicValid.state" required>
        <template #first>
          <b-form-select-option :value="null" disabled>Topic</b-form-select-option>
        </template>
      </b-select>
    </b-form-group>

    <b-form-group id="name-group" :invalid-feedback="nameValid.feedback">
      <b-form-input id="name" name="name" type="text" v-model="form.name" placeholder="Name" :state="nameValid.state" required/>
    </b-form-group>

    <b-form-group id="email-group" :invalid-feedback="emailValid.feedback">
      <b-form-input id="email" name="email" type="email" v-model="form.email" placeholder="Email" :state="emailValid.state" required/>
    </b-form-group>

    <b-button class="mt-4" type="submit" variant="primary" debounce="500">Submit</b-button>

    <p class="mt-5 small">
      <span class="text-mute">We won’t send you any emails other than the selected topic, unless you later sign up for more. For further details, review our </span>
      <b-link class="custom-link" :to="{name: 'privacy'}">Privacy Policy.</b-link>
    </p>
  </b-form>
</template>

<script>
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'contact-us',
  data: () => ({
    validate: false,
    timeout: 0,
    errorTimeout: 0,
    topics: [
      'General inquiry',
      'Request a demo',
      'Partnership',
      'Media and press'
    ],
    form: {
      topic: null,
      name: '',
      email: ''
    }
  }),
  computed: {
    topicValid () {
      if (this.validate && this.form.topic === null) {
        return { state: false, feedback: this.$refs.form.topic.validationMessage }
      }
      return { state: null }
    },
    nameValid () {
      if (this.validate && !this.form.name.length) {
        return { state: false, feedback: this.$refs.form.name.validationMessage }
      }
      return { state: null }
    },
    emailValid () {
      if (this.validate && (!this.form.email.length || !this.$refs.form.email.validity.valid)) {
        return { state: false, feedback: this.$refs.form.email.validationMessage }
      }
      return { state: null }
    }
  },
  methods: {
    countdown (counter) {
      this.timeout = counter
    },
    errorCountdown (errorCounter) {
      this.errorTimeout = errorCounter
    },
    async onSubmit () {
      if (!this.$refs.form.checkValidity()) {
        this.validate = true
        return
      }

      try {
        const response = await fetch(`${apiBaseUrl}/api/contact_us/submit/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            contact_type: this.form.topic,
            name: this.form.name,
            email: this.form.email
          })
        })
        if (response.ok) {
          this.timeout += 7
        } else {
          const message = await response.json()
          const error = new Error(message.error)
          error.status = response.status
          error.response = response
          this.errorTimeout += 7
          throw error
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.$refs.form.reset()
        this.form.topic = null
      }
    }
  }
}
</script>
