<template>
  <div class="item-carousel">
    <!-- Mobile Carousel -->
    <div class="d-md-none">
      <b-carousel ref="carouselMobile" v-model="indexMobile" :interval="interval">
        <b-carousel-slide v-for="(slide, i) in slides" :key="i">
          <template #img>
            <b-row v-if="peopleVariant" class="item-slide">
              <person-card :details="slide"/>
            </b-row>

            <b-img v-else :src="slide.src" :img-alt="slide.alt" fluid/>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- NOTE: The for-loop on the indicators might seem dumb but we need to do it this way -->
      <!--to force vue into 0-indexing the slide range. -->
      <div v-if="slides.length > 1" class="item-indicators">
        <b-icon v-for="(slide, i) in slides" :key="i" :class="mobileIndicatorClasses(i)" @click="navigateMobileTo(i)" icon="dot"/>
      </div>
    </div>

    <!-- Desktop Carousel -->
    <div class="d-none d-md-block">
      <b-carousel ref="carousel" v-model="index" :interval="interval">
        <b-carousel-slide v-for="(slide, i) in slideChunks" :key="i">
          <template #img>
            <b-row v-if="peopleVariant" class="item-slide" :cols="size">
              <person-card v-for="(item, j) in slide" :key="j" :details="item"/>
            </b-row>

            <b-row v-else :cols="size" align-h="between" align-v="center" no-gutters>
              <b-img class="item-img" v-for="(item, j) in slide" :key="j" :src="item.src" :alt="item.alt" fluid/>
            </b-row>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <div v-if="slideChunks.length > 1" class="item-indicators">
        <b-icon v-for="(slide, i) in slideChunks" :key="i" :class="indicatorClasses(i)" @click="navigateTo(i)" icon="dot"/>
      </div>
    </div>
  </div>
</template>

<script>
import PersonCard from '@/components/PersonCard'

export default {
  name: 'item-carousel',
  components: {
    PersonCard
  },
  props: {
    slides: Array,
    variant: String,
    size: {
      default: 1,
      type: Number
    }
  },
  data: () => ({
    index: 0,
    indexMobile: 0,
    interval: 5000
  }),
  computed: {
    slideChunks () {
      const slides = [...this.slides]
      return new Array(Math.ceil(slides.length / this.size)).fill().map(s => slides.splice(0, this.size))
    },
    peopleVariant () {
      if (this.variant === 'people') {
        return true
      }
      return false
    }
  },
  methods: {
    navigateTo (index) {
      this.$refs.carousel.setSlide(index)
    },
    navigateMobileTo (index) {
      this.$refs.carouselMobile.setSlide(index)
    },
    indicatorClasses (index) {
      return {
        indicator: true,
        active: this.index === index
      }
    },
    mobileIndicatorClasses (index) {
      return {
        indicator: true,
        active: this.indexMobile === index
      }
    },
    fillPath (url) {
      if (process.env.NODE_ENV === 'development') {
        return `${process.env.VUE_APP_API_BASE_URL}/media/${url}`
      }
      return url
    }
  }
}
</script>

<style lang="scss">
.item-slide {
  min-height: 23.5em;

  @media (min-width: 768px) {
    min-height: 22em;
  }
}

.item-img {
  padding-right: 3.5rem;
}

.item-indicators {
  & {
    margin-top: 3rem;
    margin-bottom: -2rem;
    font-size: 1.5em;
  }
  > .indicator.active {
    transform: scale(1.5);
    transition: transform 0.6s ease-in-out;
  }
}
</style>
