<template>
  <div>
    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section">
          <b-col md="4">
            <h1>{{ job.title }}</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5 mt-3">{{ job.intro }}</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row class="sub-section">
          <b-col class="job-section mb-5" md="4" v-html="job.role"></b-col>
          <b-col class="job-section mb-5" md="7" offset-md="1" v-html="job.requirements"></b-col>
          <b-col class="job-section mb-5 mb-md-0" md="4" v-html="job.nice_to_have"></b-col>
          <b-col class="job-section mb-md-0" md="7" offset-md="1" v-html="job.benefits"></b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section">
          <b-col class="mb-5 mb-md-0" md="4">
            <p class="h3"><span class="font-weight-bold">Job location</span></p>
            <p>{{ job.location }}</p>
          </b-col>
          <b-col md="6" offset-md="1">
            <p class="h5">To apply or get more info, please email <b-link class="custom-link" :href="`mailto:${job.email}`">{{ job.email }}</b-link></p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'careers',
  async mounted () {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/about_us/jobs/${this.$route.params.job}/`)

      if (response.ok) {
        const text = await response.json()
        this.job = text.data
      } else {
        const message = await response.json()
        const error = new Error(message.error)
        error.status = response.status
        error.response = response
        throw error
      }
    } catch (e) {
      const error = new Error(e.message)
      error.status = 500
      throw error
    }
  },
  data: () => ({
    job: {}
  })
}
</script>

<style lang="scss">
.job-section {
  > h3 {
    margin-bottom: 2rem;
  }
  > ul {
    padding-left: 0;
    margin-left: 1.2rem;
  }
  > ul > li {
    margin-bottom: 1rem;
  }
}
</style>
