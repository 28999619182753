<template>
  <main class="mkinetikos">
    <b-row class="main-section kh-bg-dark text-light" align-v="center">
      <b-col class="mb-5 mb-md-0 text-center" md="6">
        <b-img src="@/assets/img/mkinetikos-hero.png" alt="mKinetikos mobile app" fluid/>
        <!-- <b-img blank blank-color="#aaa" width="800" height="650" fluid/> -->
      </b-col>
      <b-col md="6" lg="5" xl="4">
        <h4 class="mb-5 mute">Explore mKinetikos</h4>
        <h1>Stay in control with mKinetikos</h1>
        <p class="h5 mb-5">mKinetikos is a powerful mobile app designed specifically for people with Parkinson's disease. It allows you to track your condition over time, self-manage your symptoms, and stay connected with your healthcare team.</p>
        <p class="h5 mb-5">The medical grade app is scientifically validated and based on the latest research in Parkinson's disease management.</p>
        <h5 class="mb-4">Download mKinetikos now and take the first step towards better Parkinson's disease management.</h5>
        <b-row class="mt-5" cols="1" cols-md="4">
          <b-col class="mb-4">
            <b-button variant="primary" href="https://apps.apple.com/gb/app/mkinetikos/id1564920296" target="_blank">
              <b-img src="@/assets/img/app-store-btn.svg" height="30" alt="apple store"/>
            </b-button>
          </b-col>
          <b-col offset-md="1" class="mb-4">
            <b-button variant="primary" href="https://play.google.com/store/apps/details?id=com.kinetikos.pdtraker" target="_blank">
              <b-img src="@/assets/img/google-play-btn.svg" height="30" alt="google play"/>
            </b-button>
           </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <!-- <h4 class="mb-5 mute">What is mKinetikos?</h4> -->
        <b-row>
          <b-col md="5">
            <h1>Improve quality of life through continuum healthcare</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5">Take control of your journey towards quality of life with our self-management app. mKinetikos allows you to manage your daily medication, track symptoms and initiate follow up with your clinical team so they can optimize your treatment over time.</p>
            <b-row class="my-5">
              <b-col>
                <h2 class="mute">01.</h2>
                <p>Stay connected</p>
              </b-col>
              <b-col>
                <h2 class="mute">02.</h2>
                <p>Initiate follow up with your clinical team</p>
              </b-col>
              <b-col>
                <h2 class="mute">03.</h2>
                <p>Improve quality of life</p>
              </b-col>
            </b-row>
            <!-- <b-link class="custom-link" :to="{name: 'home'}">Learn about the technology</b-link> -->
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <!-- <b-row class="main-section kh-bg-secondary-half">
      <b-container>
        <b-row>
          <b-col>
            <b-embed type="video" aspect="16by9" controls>
              <source src="@/assets/mk-video.mp4" type="video/mp4"/>
            </b-embed>
          </b-col>
        </b-row>
      </b-container>
    </b-row> -->

    <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <b-row class="sub-section" align-h="between">
          <b-col md="5">
            <h1>Start your mKinetikos journey</h1>
          </b-col>
          <b-col md="6">
            <p>Want to have more control of your condition? Feel empowered by beginning to understand patterns and taking action? With mKinetikos, you can keep track of your symptoms, medication and functional mobility.</p>
            <p>Talk to your healthcare provider to stay connected through the app. Check the resources area to know more about how to get started.</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6" offset-md="1" order-md="2">
            <b-img src="@/assets/img/mkinetikos-1.png" alt="mKinetikos event traker" fluid/>
          </b-col>
          <b-col>
            <h2>Self-Management Tracking</h2>
            <p>mKinetikos includes features to self-manage your condition. The app also allows doctors to track your symptoms and medication adherence so they may predict the severity and course of disease:</p>
            <ul>
              <li>Reminders & notifications of medication intake </li>
              <li>Digital journal to register daily symptoms and events </li>
              <li>Build better habits by tracking your physical activity </li>
            </ul>
          </b-col>
        </b-row>

        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6">
            <b-img src="@/assets/img/mkinetikos-2.png" alt="mKinetikos Echat" fluid/>
          </b-col>
          <b-col offset-md="1">
            <h2>Initiate Follow Up</h2>
            <p>The ability to self-monitor and remote reporting for PIFU (patient initiated follow up) is beneficial. You can use the mKinetikos app to:</p>
            <ul>
              <li>Initiate follow up with your clinical team</li>
              <li>Report events like falls and hospitalisations</li>
              <li>Receive clinical notifications and relevant information</li>
            </ul>
          </b-col>
        </b-row>

        <b-row class="sub-section" align-v="center">
          <b-col class="mb-5 mb-md-0" md="6" offset-md="1" order-md="2">
            <b-img src="@/assets/img/mkinetikos-3.png" alt="mKinetikos healthcare reports" fluid/>
          </b-col>
          <b-col>
            <h2>Personalized Treatment</h2>
            <p>The app collects the individual’s data between clinic appointments for the healthcare provider to interpret. Through the app, you can:</p>
            <ul>
              <li>Perform condition-relevant assessments</li>
              <li>Complete online surveys and questionnaires</li>
              <li>Get prescription updates and adapted exercise plans</li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark">
      <b-container>
        <b-card-group class="pricing" deck>
          <b-card class="pricing-card mb-5 mb-md-0" no-body>
            <template #header>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h2 class="mb-0">Basic Package</h2>
                <!-- <h4 class="mb-0 mute">— € 0</h4> -->
              </div>
            </template>
            <b-list-group>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>Medication record and reminder</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>E-diary to register daily events</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>Activity tracker and challenges</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>7-30 day report</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>News feed </span>
              </b-list-group-item>
            </b-list-group>
            <template #footer>
              <!-- REVIEW: Not sure if we'll need this button here, maybe something to consider later. -->
              <!-- <b-button>Do something</b-button> -->
            </template>
          </b-card>
          <b-card class="pricing-card" no-body>
            <template #header>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h2 class="mb-0">Premium Package</h2>
                <!-- <h4 class="mb-0 mute">— € 10 / mo.</h4> -->
              </div>
            </template>
            <b-list-group>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>All Basic Package features</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>Connect with your healthcare team (no limit)</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>Chat with your doctor</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>Get tests, on-demand questionnaires and prescription updates</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>Get customised exercise plans outlined by your HCP</span>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <b-icon class="mr-4 mb-0 h3" icon="check2"/>
                <span>Unlock full history access</span>
              </b-list-group-item>
            </b-list-group>
            <template #footer>
              <!-- REVIEW: Not sure if we'll need this button here, maybe something to consider later. -->
              <!-- <b-button>Do something</b-button> -->
            </template>
          </b-card>
          <b-row class="sub-section text-light" align-v="center">
            <p>You can talk to your healthcare provider to know if there’s a protocol between your health care provider and Kinetikos Health to access the premium package.</p>
          </b-row>
        </b-card-group>
      </b-container>
    </b-row>

    <medical-device class="kh-bg-secondary" product="mKinetikos"/>
  </main>
</template>

<script>
import MedicalDevice from '@/components/MedicalDevice'

export default {
  name: 'mkinetikos',
  components: {
    'medical-device': MedicalDevice
  }
}
</script>

<style lang="scss">
.embed-responsive {
  box-shadow: 24px 24px 32px transparentize(#000, 0.75);
}

.pricing-card {
  border: none;
  border-radius: 0;
  background: linear-gradient(-4deg, #126497 0%, #186FA5 100%);
  box-shadow: 12px 12px 20px transparentize(#000, 0.75);
  color: #f8f9fa;
}
</style>
