<template>
  <b-modal id="promo-video" size="xl" body-class="p-0" hide-footer hide-header centered>
    <template #default>
      <b-container fluid>
        <b-row>
          <b-embed type="video" aspect="16by9" controls autoplay>
            <source src="@/assets/mk-video.mp4" type="video/mp4"/>
          </b-embed>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'promo-video'
}
</script>

<style lang="scss">
#promo-video {
  .modal-content {
    border: 0;
    border-radius: 0;
    background-color: #012840;
  }
}
</style>
