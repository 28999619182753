<template>
  <div class="blog-post">
    <b-row class="main-section">
      <b-container>
        <b-breadcrumb class="custom-crumbs" :items="crumbs"/>

        <b-row class="post-header sub-section" no-gutters>
          <b-col class="post-title" md="8" offset-md="1">
            <h1>{{ post.title }}</h1>
          </b-col>

          <b-img class="post-img" :src="post.img.src" :alt="post.img.alt" fluid-grow/>

          <b-col md="8" offset-md="1">
            <p class="post-date">Last update: {{ post.date }}</p>
            <p class="post-headline">{{ post.headline }}</p>
            <share-links :post="post"/>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row class="sub-section">
          <b-col class="post-content" md="8" offset-md="1" v-html="post.content"/>

          <b-col class="post-sharing" md="8" offset-md="1">
            <share-links :post="post"/>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </div>
</template>

<script>
import ShareLinks from '@/components/ShareLinks'

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'blog-post',
  components: {
    ShareLinks
  },
  async mounted () {
    try {
      const response = await fetch(`${apiBaseUrl}/api/blog/${this.$route.params.slug}/`)
      if (response.ok) {
        const text = await response.json()
        this.post = {
          title: text.data.title,
          headline: text.data.headline,
          content: text.data.content,
          img: {
            src: apiBaseUrl + text.data.img,
            alt: text.data.img_alt
          },
          date: new Date(text.data.creation_date).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }),
          slug: text.data.slug
        }
      } else {
        const message = await response.json()
        console.error(message.error)
      }
    } catch (e) {
      console.error(e)
    }
  },
  data: () => ({
    post: {}
  }),
  computed: {
    crumbs () {
      return [
        { text: 'Blog', href: '/blog' },
        { text: this.post.title, active: true }
      ]
    }
  }
}
</script>

<style lang="scss">
.custom-crumbs {
  background-color: inherit;
  margin-bottom: 3rem;
  padding: 0;
}

.post-header {
  > .post-title {
    margin-bottom: 4rem;
  }
  > .post-img {
    margin-bottom: 4rem;
    box-shadow: 12px 12px 24px transparentize(#000, 0.85);
  }
  .post-date {
    opacity: 0.5;
  }
  .post-headline {
    font-size: 1.25em;
    line-height: 1.5;
  }
}

.post-content {
  > h1,
  > h2,
  > h3 {
    &:not(:first-child) {
      margin-top: 3rem;
    }
    margin-bottom: 1.5rem;
  }
  > h1 {
    font-size: 2.5rem;
  }
  > h2 {
    font-size: 2rem;
  }
  > h3 {
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
  > ul {
    padding-left: 0;
    margin: 3rem 1.2rem 3rem;
  }
  > ul > li {
    margin-bottom: 1rem;
  }
}
</style>
