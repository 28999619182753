<template>
  <div class="blog">
    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section">
          <b-col md="4">
            <h1>Kinetikos Health Blog</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5 mt-3">Health information, articles, news and advice.</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row v-if="articles.length" cols="1" cols-md="3">
          <b-col v-for="(article, i) in articles" :key="i" class="post" @click="openPost(article.slug)">
            <div class="post-thumbnail">
              <b-img-lazy class="post-thumbnail-img" :src="article.img.src" :alt="article.img.alt" fluid/>
              <div class="post-thumbnail-overlay"></div>
            </div>
            <p class="post-date">{{ article.date }}</p>
            <h2 class="post-title">{{ article.title }}</h2>
            <p>{{ article.headline }}</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </div>
</template>

<script>
// import { blogPosts as tempBlogPosts } from '@/temp-data'

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'blog',
  mounted () {
    this.getBlogPosts()
  },
  data: () => ({
    articles: []
  }),
  methods: {
    openPost (slug) {
      this.$router.push({ path: `blog/${slug}` })
    },
    async getBlogPosts () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/blog/list/`)
        if (response.ok) {
          const text = await response.json()
          this.articles = text.data.map(a => ({
            slug: a.slug,
            img: {
              src: `${apiBaseUrl}/media/${a.img}`,
              alt: a.img_alt
            },
            date: new Date(a.creation_date).toLocaleDateString('en-GB', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }),
            title: a.title,
            headline: a.headline,
            content: a.content
          }))
        } else {
          const message = await response.json()
          console.error(message.error)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.post {
  & {
    border-radius: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 2rem;
    transition: background-color .2s ease;
    cursor: pointer;
  }
  &:hover {
    background-color: #dfe1e5;
  }
  &:hover > .post-thumbnail > .post-thumbnail-img {
    box-shadow: 6px 6px 12px transparentize(#000, 0.7);
  }
  &:hover > .post-thumbnail > .post-thumbnail-overlay {
    opacity: 0;
  }
  &:hover > .post-date {
    color: #05c2b9;
    opacity: 1;
  }
  > .post-thumbnail {
    position: relative;
    margin-bottom: 2rem;
  }
  > .post-title {
    margin-bottom: 1.5rem;
  }
  > .post-date {
    margin-bottom: 1rem;
    font-weight: 600;
    opacity: 0.3;
  }
}

.post-thumbnail {
  > .post-thumbnail-overlay {
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    transition: .2s ease;
  }
}
</style>
