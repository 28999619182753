<template>
  <b-row class="main-section bg-white" v-if="milestones.length">
    <b-container>
      <h2>Milestones</h2>
      <b-row cols="2" cols-md="4">
        <b-col class="sub-section" v-for="(stone, i) in milestones" :key="i">
          <h2 class="mb-4 mute">{{ stone.year }}</h2>
          <div v-html="stone.text"></div>
        </b-col>
      </b-row>
    </b-container>
  </b-row>
</template>

<script>
export default {
  name: 'milestones',
  async mounted () {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/about_us/milestones/`)
      if (response.ok) {
        const text = await response.json()
        this.milestones = text.data
      } else {
        const message = await response.json()
        const error = new Error(message.error)
        error.status = response.status
        error.response = response
        throw error
      }
    } catch (e) {
      const error = new Error(e.message)
      error.status = 500
      throw error
    }
  },
  data: () => ({
    milestones: []
  })
}
</script>
