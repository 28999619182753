<template>
  <b-nav class="share-links">
    <b-nav-item @click="shareOnTwitter">
      <b-icon-twitter/>
    </b-nav-item>
    <b-nav-item @click="shareOnFacebook">
      <b-icon-facebook/>
    </b-nav-item>
    <b-nav-item @click="shareOnLinkedin">
      <b-icon-linkedin/>
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  name: 'share-links',
  props: {
    post: Object
  },
  methods: {
    shareOnTwitter () {
      const tweet = `${this.post.title} by @kinetikoshealth kinetikoshealth.com/blog/${this.post.slug}`
      window.open(
        `https://twitter.com/intent/tweet?text=${tweet}`
      )
    },
    shareOnFacebook () {
      const url = 'kinetikoshealth.com'
      window.open(
        `https://facebook.com/sharer.php?u=${url}`,
        'popup',
        'width=500,height=600,left=620,top=150'
      )
    },
    shareOnLinkedin () {
      const params = {
        url: `kinetikoshealth.com/blog/${this.post.slug}`,
        title: this.post.title,
        summary: 'this is a summary',
        source: 'kinetikoshealth.com'
      }
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${params.url}&title=${params.title}&summary=${params.summary}&source=${params.source}`,
        'popup',
        'width=500,height=600,left=620,top=150'
      )
    }
  }
}
</script>

<style lang="scss">
.share-links {
  margin-top: 3rem;

  .nav-link {
    padding-left: 0;
    padding-right: 1.5rem;
  }
}
</style>
