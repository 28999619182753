<template>
  <b-container class="app kh-bg-light" fluid>
    <v-header/>

    <router-view/>

    <v-footer/>
  </b-container>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    'v-header': Header,
    'v-footer': Footer
  }
}
</script>

<style lang="scss">
@import './assets/main.scss';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.app {
  h1, .h1  {
    font-weight: 700;
    font-size: 3em;
    line-height: 1.3;
    margin-bottom: 2rem;
  }
  h2, .h2 {
    font-weight: 700;
    font-size: 2em;
    line-height: 1.4;
    margin-bottom: 1.5rem;

    &.mute {
      color: #05d1c7;
      opacity: 0.8;
      margin-bottom: 1rem;
    }
  }
  h3, .h3 {
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }
  h4, .h4 {
    font-weight: 600;
    font-size: 1.25em;
    line-height: 1.5;

    &.mute {
      opacity: 0.5;
    }
  }
  h5, .h5 {
    font-size: 1.125em;
    line-height: 1.5;
  }
  h6, .h6 {
    font-weight: 700;
    line-height: 1.3;
  }
  p {
    margin-bottom: 1.5rem;
  }
  p.small {
    font-size: 0.87em;
    font-weight: initial;

    &.mute {
      opacity: 0.5;
    }
  }
  .list-bullet {
    font-weight: 600;
    color: #05d1c7;
  }
}

a.custom-link {
  font-weight: 600;
  color: inherit;
  border-bottom: solid 2px;
  border-color: transparentize(black, 0.5);
  opacity: 0.5;

  &.light {
    border-color: transparentize(white, 0.5);
  }

  &:hover {
    color: #05d1c7;
    text-decoration: none;
    border-color: transparentize(black, 1);
    opacity: 1;
  }
  &.light:hover {
    color: #05f2db;
    text-decoration: none;
    border-color: transparentize(black, 1);
    opacity: 1;
  }
}

.custom-tabs {
  & {
    border: 0;
    font-size: 1.06em;
  }
  .nav-link {
    border: 0;
    padding: 0;
    margin-bottom: 1.5rem;
    color: inherit;
    opacity: 0.4;
  }
  .nav-link:hover {
    opacity: 1;
    color: #05d1c7; // TODO: All of these loose colors neeed to be abstracted into variables at some point.
  }
  .nav-link:focus {
    outline: none;
  }
  .nav-link.active {
    background: none;
    border: 0;
    color: inherit;
    opacity: 1;
  }
}

.text-mute {
  opacity: 0.5;
}

.pt2020-section {
  ul {
    padding-left: 0;
    margin-left: 1.2rem;
  }
  ul > li {
    padding-left: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

.list-group-item {
  padding: 0.75rem 1.25rem;
  background: none;
  border: 0;
  border-bottom: 1px solid transparentize(black, 0.8);
}

.list-group-item-action {
  padding: 0.75rem 1.25rem;
  font-size: 1.13em;
  font-weight: 600;
  padding-left: 0;
  background: none;
  border: 0;
  border-bottom: 1px solid transparentize(white, 0.8);
  color: transparentize(#f8f9fa, 0.5);

  &:hover {
    background: none;
    color: #f8f9fa;
  }
}

.btn {
  font-weight: 600;
  padding: 0.62rem 1.13rem;
  border-radius: 0;
  box-shadow: 12px 12px 20px transparentize(#000, 0.85);

  &:focus {
    box-shadow: 12px 12px 20px transparentize(#000, 0.85);
  }
}

.btn-primary-dark {
  box-shadow: 12px 12px 20px transparentize(#000, 0.7);
}

.img-drop-shadow {
  box-shadow: 12px 12px 24px transparentize(#000, 0.82);
}

.form-control {
  border-radius: 0;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.62rem 1.13rem;
  border: 1px solid #fff;
  box-shadow: 8px 8px 12px #e3e3e3;

  &::placeholder {
    color: #ababab;
  }

  &:focus {
    border-color: #05d1c7;
    outline-color: #05d1c7;
  }
}

.form-control.dark {
  border: 1px solid transparentize(#fff, 0.8);
  background-color: transparentize(#fff, 0.8);
  box-shadow: 12px 12px 20px transparentize(#000, 0.8);

  &::placeholder {
    color: #adadad;
  }
  &:focus {
    background-color: transparentize(#fff, 0.8);
    color: white;
  }
}

.custom-select {
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.62rem 1.13rem;
  border-radius: 0;
  border: 1px solid #fff;
  box-shadow: 8px 8px 12px #e3e3e3;
}

.main-section {
  padding-top: 4rem;
  padding-bottom: 4rem;

  .sub-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

// Backgrounds
//

.kh-bg-light {
  background-color: #f1f2f5;
}
.kh-bg-darker {
  background-color: #012840;
}
.kh-bg-dark {
  // background-color: #024066;
  background: linear-gradient(0deg,#075e96,#1784e2);
}
.kh-bg-secondary {
  background-color: #e7e9ee;
}
.kh-bg-secondary-half {
  background: linear-gradient(
    180deg,
    #f1f2f5 0%,
    #f1f2f5 33%,
    #e7e9ee 33%,
    #e7e9ee 100%
  );
}
</style>
