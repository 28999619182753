<template>
  <main class="contact_us">
    <b-row class="main-section" id="contacts">
      <b-container>
        <b-row class="sub-section">
          <b-col md="4">
            <h1>Contact us</h1>
          </b-col>
          <b-col offset-md="2">
            <p class="h5">If you have any inquires or want more information on a particular topic, fill out the application and choose a topic.</p>
          </b-col>
        </b-row>

        <b-row class="sub-section">
          <b-col class="mb-5" md="5" order-md="2">
            <contact-us/>
          </b-col>
          <b-col class="mt-5 mt-md-0" md="6">
            <b-tabs nav-wrapper-class="col-6 col-md-5" nav-class="custom-tabs" vertical>
              <b-tab title="Headquarters">
                <p class="mb-4">Rua Pedro Nunes C<br>3030-199 Coimbra<br>Portugal</p>
                <p class="mb-4">+351 239 099 581</p>
                <b-link class="custom-link mb-3" href="mailto:info@kinetikoshealth.com">info@kinetikoshealth.com</b-link>
              </b-tab>
              <b-tab title="Lisbon" active>
                <p class="mb-4">Campo Grande 28, 10º D<br>1700-093 Lisbon<br>Portugal</p>
                <p class="mb-4">+351 215 836 938</p>
                <b-link class="custom-link mb-3" href="mailto:info@kinetikoshealth.com">info@kinetikoshealth.com</b-link>
              </b-tab>
              <b-tab title="London">
                <p class="mb-4">71-75 Shelton Street<br>Greater London<br>WC2H 9JQ<br>United Kingdom</p>
                <b-link class="custom-link mb-3" href="mailto:info@kinetikoshealth.com">info@kinetikoshealth.com</b-link>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </main>
</template>

<script>
import ContactUs from '@/components/ContactUs'

export default {
  name: 'ContactUsForm',
  components: {
    ContactUs
  }
}
</script>
