<template>
  <main class="about">
    <b-row class="main-section kh-bg-dark text-light" align-v="center">
      <b-col class="mb-5 mb-md-0 text-center" md="6">
        <b-img src="@/assets/img/about-hero-2.png" alt="kinetikos logo with points" fluid/>
      </b-col>
      <b-col md="6" lg="5" xl="4">
        <h1>Built on science, moved to measure</h1>
        <p class="h5">Digital health company revolutionising the standard of care for people with Movement Disorders. We instantly translate patient's movement into clinical insight.</p>
        <p class="h5">At Kinetikos Health, we believe that what cannot be measured, cannot be managed. That’s why we quantify movement.</p>
      </b-col>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <h4 class="mute mb-5">Our Mission</h4>
        <b-row>
          <b-col md="5">
            <h1>To revolutionise the standard of care for movement disorders</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5">Movement Disorders are worldwide. We exist to help healthcare providers, businesses and patients make patient monitoring more efficient and meaningful. How? By combining research with technology to tackle the number one cause of years lived with a disability.</p>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <b-row class="mb-5">
          <b-col md="4">
            <h2 class="mb-5 mb-md-0">Awards and grants received</h2>
          </b-col>
          <b-col offset-md="2">
            <p class="h5">From being the first Portuguese SME to lead a Horizon 2020 Healthcare project to being in the forefront of movement analysis software, we have won several awards for innovation and technological advances.</p>
          </b-col>
        </b-row>
        <item-carousel :slides="awards" :size="4"/>
      </b-container>
    </b-row>

    <milestones/>

    <b-row class="main-section" id="meet-the-team">
      <b-container>
        <b-row class="sub-section mb-4">
          <b-col md="5">
            <h1>Meet the team</h1>
          </b-col>
          <b-col offset-md="1">
            <p class="h5">Meet a team of passionate and specialists in AI Software, Biomechanics and Business Strategy. Our awarded scientific team totals more than 120 presentations in national and international conferences, more than 50 published peer-reviewed papers among conferences and international journals, and an active collaboration as manuscript reviewers in some of the main clinical and biomechanics journals.</p>
          </b-col>
        </b-row>

        <b-row class="sub-section" cols-md="3">
          <person-card v-for="(pax, j) in management" :key="j" :details="pax"/>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark text-light">
      <b-container>
        <item-carousel variant='people' :slides="staff" :size="3"/>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row class="sub-section">
          <b-col md="4">
            <h1>Team Culture</h1>
          </b-col>
          <b-col offset-md="2">
            <p class="h5">We wanted to create an environment where talented people could do their best work and improve people’s lives. These are the values we live by:</p>
          </b-col>
        </b-row>
        <b-row class="sub-section">
          <b-col offset-md="5">
            <b-row>
              <b-col class="py-4" md="6">
                <h2 class="mute">01.</h2>
                <h3>Focus on impact</h3>
                <p>We believe we can make a real impact on people’s health. That means each day is an act of putting things into action to help patients  with our smart technology.</p>
              </b-col>
              <b-col class="py-4" md="6">
                <h2 class="mute">02.</h2>
                <h3>Keep growing</h3>
                <p>We believe your only barriers are those that you set yourself. Everyday we focus on gaining traction/scale globally as we want more people to benefit from our solution. </p>
              </b-col>
              <b-col class="py-4" md="6">
                <h2 class="mute">03.</h2>
                <h3>Enjoy the ride</h3>
                <p>Fast growing companies have a lot of dynamism and unpredictability. We embrance that, keep the team spirit and enjoy the ride as we see things evolving.</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-dark text-light" id="careers">
      <b-container>
        <b-row class="sub-section">
          <b-col md="4">
            <h1>Careers</h1>
          </b-col>
          <b-col md="6" offset-md="2">
            <p class="h5 mb-5">Want to make an impact on people’s lives? We want to revolutionise the standard of care for Movement Disorders. To do that, we need brilliant, driven, passionate people in every position. Join us.</p>
            <b-row>
              <b-col md="10">
                <p v-if="!jobs.length" class="h5">Send your CV and a brief introduction to <b-link class="custom-link light" href="mailto:recruitment@kinetikoshealth.com">recruitment@kinetikoshealth.com</b-link></p>
                <b-list-group v-else>
                  <b-list-group-item v-for="(job, index) in jobs" :key="index" :to="{path: `/careers/${encodeURI(job.title)}`}">{{ job.title }}</b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section" id="contacts">
      <b-container>
        <b-row class="sub-section">
          <b-col md="4">
            <h1>Contact us</h1>
          </b-col>
          <b-col offset-md="2">
            <p class="h5">If you have any inquires or want more information on a particular topic, fill out the application and choose a topic.</p>
          </b-col>
        </b-row>

        <b-row class="sub-section">
          <b-col class="mb-5" md="5" order-md="2">
            <contact-us/>
          </b-col>
          <b-col class="mt-5 mt-md-0" md="6">
            <b-tabs nav-wrapper-class="col-6 col-md-5" nav-class="custom-tabs" vertical>
              <b-tab title="Headquarters">
                <p class="mb-4">Rua Pedro Nunes C<br>3030-199 Coimbra<br>Portugal</p>
                <p class="mb-4">+351 239 099 581</p>
                <b-link class="custom-link mb-3" href="mailto:info@kinetikoshealth.com">info@kinetikoshealth.com</b-link>
              </b-tab>
              <b-tab title="Lisbon" active>
                <p class="mb-4">Campo Grande 28, 10º D<br>1700-093 Lisbon<br>Portugal</p>
                <p class="mb-4">+351 215 836 938</p>
                <b-link class="custom-link mb-3" href="mailto:info@kinetikoshealth.com">info@kinetikoshealth.com</b-link>
              </b-tab>
              <b-tab title="London">
                <p class="mb-4">71-75 Shelton Street<br>Greater London<br>WC2H 9JQ<br>United Kingdom</p>
                <b-link class="custom-link mb-3" href="mailto:info@kinetikoshealth.com">info@kinetikoshealth.com</b-link>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </main>
</template>

<script>
import ItemCarousel from '@/components/ItemCarousel'
import PersonCard from '@/components/PersonCard'
import Milestones from '@/components/Milestones'
import ContactUs from '@/components/ContactUs'

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'About',
  components: {
    ItemCarousel,
    PersonCard,
    Milestones,
    ContactUs
  },
  async mounted () {
    this.getAwards()
    this.getManagement()
    this.getStaff()
    this.getJobs()
  },
  data: () => ({
    awards: [],
    management: [],
    staff: [],
    jobs: []
  }),
  methods: {
    async getAwards () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/about_us/carousel/awards/`)
        if (response.ok) {
          const text = await response.json()
          this.awards = text.data.map(a => ({
            src: `${apiBaseUrl}/media/${a.img}`,
            alt: a.alt
          }))
        } else {
          const message = await response.json()
          console.error(message.error)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getManagement () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/about_us/team/management/`)
        if (response.ok) {
          const text = await response.json()
          this.management = text.data.map(m => ({
            name: m.name,
            title: m.title,
            statement: m.description,
            img: {
              src: `${apiBaseUrl}/media/${m.photo}`,
              alt: m.photo_alt
            }
          }))
        } else {
          const message = await response.json()
          console.error(message.error)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getStaff () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/about_us/team/kinetikos/`)
        if (response.ok) {
          const text = await response.json()
          this.staff = text.data.map(s => ({
            name: s.name,
            title: s.title,
            statement: s.description,
            img: {
              src: `${apiBaseUrl}/media/${s.photo}`,
              alt: s.photo_alt
            }
          }))
        } else {
          const message = await response.json()
          const error = new Error(message.error)
          error.status = response.status
          error.response = response
          throw error
        }
      } catch (e) {
        const error = new Error(e.message)
        error.status = 500
        throw error
      }
    },
    async getJobs () {
      try {
        const response = await fetch(`${apiBaseUrl}/api/about_us/jobs/list/`)
        if (response.ok) {
          const text = await response.json()
          this.jobs = text.data
        } else {
          const message = await response.json()
          const error = new Error(message.error)
          error.status = response.status
          error.response = response
          throw error
        }
      } catch (e) {
        const error = new Error(e.message)
        error.status = 500
        throw error
      }
    }
  }
}
</script>
