<template>
  <b-row id="footer" class="footer mt-auto">
    <b-container class="text-light">
      <b-row class="footer-section">
        <b-col class="mb-5 mb-md-0" md="3">
          <b-row cols-md="1">
            <b-col class="mb-4 mb-md-5">
              <b-link :to="{name: 'home'}">
                <b-img src="@/assets/img/kinetikos-health.svg" height="34"/>
              </b-link>
            </b-col>
            <b-col>
              <b-nav class="footer-nav">
                <b-nav-item class="footer-nav-item" target="_blank" href="https://twitter.com/kinetikoshealth">
                  <b-icon-twitter/>
                </b-nav-item>
                <b-nav-item class="footer-nav-item" target="_blank" href="https://www.facebook.com/kinetikoshealth">
                  <b-icon-facebook/>
                </b-nav-item>
                <b-nav-item class="footer-nav-item" target="_blank" href="https://pt.linkedin.com/company/kinetikoshealth">
                  <b-icon-linkedin/>
                </b-nav-item>
              </b-nav>
            </b-col>
          </b-row>
        </b-col>

        <b-col class="mb-5 mb-md-0" md="5">
          <b-row>
            <b-col>
              <b-nav class="footer-nav" vertical>
                <b-nav-item class="footer-nav-item" v-b-modal.book-demo>Book a Demo</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ path: '/#our-partners' }">Our Partners</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ path: '/about/#careers' }">Careers</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ path: '/about/#contacts' }">Contacts</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ path: '/resources' }">Resources</b-nav-item>
              </b-nav>
            </b-col>

            <b-col>
              <b-nav class="footer-nav" vertical>
                <b-nav-item class="footer-nav-item" :to="{ name: 'home' }">Home</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ name: 'kinetikos' }">Kinetikos</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ name: 'mkinetikos' }">mKinetikos</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ name: 'about' }">About Us</b-nav-item>
                <b-nav-item class="footer-nav-item" :to="{ name: 'blog' }">Blog</b-nav-item>
              </b-nav>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="4">
          <b-form ref="news" @submit.stop.prevent="onSubmitNewsletter" novalidate>
            <label class="mb-5">Subscribe to our newsletter to stay up-to-date about news, releases and opportunities.</label>
            <b-alert variant="success" :show="timeout" @dismiss-count-down="countdown" dismissible>Successfully subscribed!</b-alert>

            <b-alert variant="danger" :show="errorTimeout" @dismiss-count-down="errorCountdown" dismissible>We seem to be having some issues. Please try again in a few moments.</b-alert>

            <b-form-row>
              <b-col cols="9">
                <b-form-group class="mr-3" id="newsletter-group" :invalid-feedback="emailValid.feedback">
                  <b-form-input class="dark" id="newsletter" v-model="email" type="email" name="email" placeholder="Email address" :state="emailValid.state" required/>
                </b-form-group>
              </b-col>
              <b-col>
                <b-button class="btn-primary-dark" type="submit" variant="primary" debounce="500">
                  <b-icon icon="arrow-return-left"></b-icon>
                </b-button>
              </b-col>
            </b-form-row>
            <p class="mt-3">
              <span class="footer-info">You can unsubscribe anytime. For more details, review our </span>
              <b-link class="custom-link light" :to="{ name: 'privacy' }">privacy policy.</b-link>
            </p>
          </b-form>
        </b-col>
      </b-row>

      <b-row class="footer-section" align-v="center">
        <b-col md="6" lg="4" order-md="2">
          <b-nav class="footer-nav">
            <b-nav-item class="footer-nav-item mr-2" :to="{name: 'disclaimer'}">Disclaimer</b-nav-item>
            <b-nav-item class="footer-nav-item mr-2" :to="{name: 'privacy'}">Privacy</b-nav-item>
            <b-nav-item class="footer-nav-item" :to="{name: 'cookies'}">Cookies</b-nav-item>
          </b-nav>
        </b-col>
        <b-col class="footer-info mt-5 mt-md-0" order-md="1">
          <p class="mb-0">© {{ currentYear }} Kinetikos Health</p>
        </b-col>
      </b-row>
    </b-container>

    <book-a-demo/>
  </b-row>
</template>

<script>
import BookDemoModal from '@/components/BookDemoModal'

export default {
  components: {
    'book-a-demo': BookDemoModal
  },
  data: () => ({
    validate: false,
    timeout: 0,
    errorTimeout: 0,
    email: ''
  }),
  computed: {
    currentYear () {
      return new Date().getFullYear()
    },
    emailValid () {
      if (this.validate && (!this.email.length || !this.$refs.news.email.validity.valid)) {
        return { state: false, feedback: this.$refs.news.email.validationMessage }
      }
      return { state: null }
    }
  },
  methods: {
    countdown (counter) {
      this.timeout = counter
    },
    async onSubmitNewsletter () {
      if (!this.$refs.news.checkValidity()) {
        this.validate = true
        return
      }
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/newsletter/submit/`, {
          method: 'POST',
          body: JSON.stringify({ email: this.email })
        })
        if (response.ok) {
          this.timeout += 7
        } else {
          const message = await response.json()
          const error = new Error(message.error)
          error.status = response.status
          error.response = response
          this.errorTimeout += 7
          throw error
        }
      } catch (e) {
        const error = new Error(e.message)
        error.satus = 500
        throw error
      } finally {
        this.$refs.news.reset()
      }
    }
  }
}
</script>

<style lang="scss">
.footer {
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 0.94em;
  background: linear-gradient(0deg,#075e96,#1784e2);
  box-shadow: inset 0 6px 24px transparentize(#000, 0.6);
}

.footer-section {
  padding: 2rem 0;
}

.flex-column {
  > .footer-nav-item > .nav-link {
    padding-bottom: 1rem;
  }
}

.footer-nav-item {
  > .nav-link {
    padding: 0;
    margin-right: 1.3rem;
    color: inherit;
    opacity: 0.6;
  }
  > .nav-link:hover {
    opacity: 1;
  }
}

.footer-info {
  opacity: 0.4;
}
</style>
