<template>
  <b-col v-if="details" class="person-card">
    <b-media class="person-header">
      <template #aside>
        <b-img class="person-img" :src="details.img.src" :alt="details.img.alt"/>
      </template>
      <h6>{{ details.name }}</h6>
      <p class="person-title">{{ details.title }}</p>
    </b-media>
    <p>{{ details.statement }}</p>
  </b-col>
</template>

<script>
export default {
  name: 'person-card',
  props: {
    details: Object
  }
}
</script>

<style lang="scss">
.person-card {
  &:not(:last-child) {
    margin-bottom: 5rem;
  }
  @media (min-width: 768px) {
    &:nth-last-child(2), &:nth-last-child(3) {
      margin-bottom: 0;
    }
  }
}

.person-header {
  margin-bottom: 3rem;
}

.person-img {
  height: 6.44em;
  box-shadow: 8px 8px 16px transparentize(#000, 0.84);
}

.person-title {
  font-size: 0.87em;
  font-weight: initial;
  opacity: 0.5;
}
</style>
