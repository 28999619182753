<template>
  <div class="hero-carousel">
    <!-- Mobile Carousel -->
    <b-carousel class="d-md-none" v-model="index" :interval="interval">
      <b-carousel-slide v-for="(slide, i) in slides" :key="i">
        <template #img>
          <b-card class="hero-card" body-class="hero-body" :src="slide.img.src_sm" :img-alt="slide.img.alt" overlay>
            <a :href="slide.url" style="text-decoration: none; color: inherit" target=black>
              <b-container class="hero-content">
                <b-row align-v="center" :align-h="slide.align">
                  <b-col class="hero-text" :class="slide.style" md="6">
                    <h1 class="hero-title">{{ slide.title }}</h1>
                    <p class="hero-subtitle">{{ slide.subtitle }}</p>
                  </b-col>
                </b-row>
              </b-container>
            </a>
          </b-card>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <!-- Desktop Carousel -->
    <b-carousel class="d-none d-md-flex" ref="mobileHero" v-model="index" :interval="interval">
      <b-carousel-slide v-for="(slide, i) in slides" :key="i">
        <template #img>
          <b-card class="hero-card" body-class="hero-body" :img-src="slide.img.src_md" :img-alt="slide.img.alt" overlay>
            <a :href="slide.url" style="text-decoration: none; color: inherit" target=black>
              <b-container class="hero-content">
                <b-row align-v="center" :align-h="slide.align">
                  <b-col class="hero-text" :class="slide.style" md="6">
                    <h1 class="hero-title">{{ slide.title }}</h1>
                    <p class="hero-subtitle">{{ slide.subtitle }}</p>
                  </b-col>
                </b-row>
              </b-container>
            </a>
          </b-card>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <b-container v-if="slides.length > 1" class="hero-indicators">
      <b-icon v-for="(slide, i) in slides" :key="i" :class="indicatorClasses(i)" @click="navigateTo(i)" icon="dot"/>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'hero-carousel',
  props: {
    slides: Array
  },
  data: () => ({
    index: 0,
    interval: 5000
  }),
  methods: {
    navigateTo (index) {
      this.$refs.mobileHero.setSlide(index)
    },
    indicatorClasses (index) {
      const currentIndex = this.index
      return {
        indicator: true,
        active: currentIndex === index,
        ...this.slides[currentIndex].style
      }
    }
  }
}
</script>

<style lang="scss">
.hero-carousel {
  margin-left: -15px;
  margin-right: -15px;
}

.hero-card {
  background: none;
  border-radius: 0;
  border: 0;
}

.hero-body {
  padding: 0;
}

.hero-content {
  &,
  > .row {
    height: 100%;
  }
}

.hero-text {
  &.light {
    color: #f8f9fa;
  }
  &.shadow {
    text-shadow: 0px 0px 12px transparentize(black, 0.6);
  }
  > .hero-title {
    margin-bottom: 3rem;
    font-size: 3.5em;
    line-height: 1.4;
  }
  > .hero-subtitle {
    font-weight: 600;
    font-size: 1.25em;
    line-height: 1.5;
  }
}

.hero-indicators {
  & {
    position: relative;
    bottom: 3rem;
    font-size: 1.5rem;

    @media (min-width: 768px) {
      top: -4rem;
    }
  }
  > .indicator.active {
    transform: scale(1.5);
    transition: transform 0.6s ease-in-out;
  }
  > .indicator.light {
    color: #f8f9fa;
  }
  > .indicator.shadow {
    text-shadow: 0px 0px 12px transparentize(black, 0.6);
  }
}
</style>
